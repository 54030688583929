<template>
  <div>
    <div class="form">
      <div class="row">
        <div class="column">
          <Input
            type="text"
            title="Titre *"
            name="activity-name"
            v-model="originalActivity.title"
          />

          <Textarea
            title="Description"
            name="activity-description"
            v-model="originalActivity.description"
          />
        </div>
        <div class="column">
          <InputFile
            type="image"
            title="Image *"
            name="activity-image"
            :preview="originalActivity.image.url"
            v-model="originalActivity.image.id"
            :onUploadStarts="() => (isUploadingThumbnail = true)"
            :onUploadEnds="() => (isUploadingThumbnail = false)"
          />
        </div>
      </div>

      <div class="row">
        <div class="column">
          <InputFile
            type="video"
            title="Vidéo *"
            name="activity-video"
            @getDurationVideo="getDurationVideo"
            @changeUrl="changeUrl"
            :preview="originalActivity.video.url"
            v-model="originalActivity.video.id"
            :onUploadStarts="() => (isUploadingVideo = true)"
            :onUploadEnds="() => (isUploadingVideo = false)"
          />
        </div>
        <div class="column">
          <MultipleSelect
            title="Capacités fonctionnelles *"
            name="activity-capacities"
            :options="functionalCapacities"
            :value="[...originalActivity.functionalCapacities]"
            v-model="originalActivity.functionalCapacities"
          />
          <MultipleSelect
            title="Matériel"
            name="activity-equipments"
            :options="equipments"
            :value="[...originalActivity.equipments]"
            v-model="originalActivity.equipments"
          />
          <Select
            title="Intensité de l'exercice *"
            name="activity-intensity"
            :options="difficultyLevels"
            v-model="originalActivity.intensity"
          />
          <Select
            title="Thème de l'exercice *"
            name="activity-type"
            :options="exerciseTypes"
            v-model="originalActivity.type"
          />
        </div>
      </div>
    </div>

    <BottomBar v-if="mode === 'edit'">
      <div class="message">
        <p v-if="hasActivityChanged">Modifications non sauvegardées</p>
      </div>

      <Button
        title="Sauvegarder"
        type="primary"
        :disabled="isReadyToSave"
        @click="submit"
      />
      <Button title="Supprimer" type="warning" @click="showRemoveActivityPopup" />
    </BottomBar>

    <BottomBar v-if="mode === 'add'">
      <div class="message">
        <p v-if="hasActivityChanged">Modifications non sauvegardées</p>
      </div>

      <Button
        title="Sauvegarder"
        type="primary"
        :disabled="isReadyToSave"
        @click="submit"
      />
    </BottomBar>
    <ConfirmationModal
      v-if="showRemoveModal"
      @close="showRemoveModal = false"
      @confirm="removeActivity"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import BottomBar from "@/components/Layout/BottomBar"
import Input from "@/components/Forms/Fields/Input"
import Textarea from "@/components/Forms/Fields/Textarea"
import InputFile from "@/components/Forms/Fields/InputFile"
import Select from "@/components/Forms/Fields/Select"
import MultipleSelect from "@/components/Forms/Fields/MultipleSelect"
import Button from "@/components/Button"
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"

export default {
  name: "ActivityForm",
  components: {
    Input,
    Textarea,
    InputFile,
    Select,
    MultipleSelect,
    Button,
    BottomBar,
    ConfirmationModal
  },
  props: {
    activity: {
      default: {}
    },
    mode: {
      type: String,
      default: "edit"
    }
  },
  data() {
    return {
      durationVideo: 0,
      originalActivity: {},
      isUploadingThumbnail: false,
      isUploadingVideo: false,
      isSubmitting: false,
      showRemoveModal: false,
      allFunctionalCapacities : []
    }
  },
  async created() {
    this.originalActivity = _.clone(this.activity)
    if (this.originalActivity.functionalCapacities) {
      this.originalActivity.functionalCapacities = this.originalActivity.functionalCapacities.map(el  => el.index.toString())
    }
    await this.$store.dispatch("admin/getEquipments")
    if (this.originalActivity.equipments?.length) {
      this.originalActivity.equipments = this.originalActivity.equipments.map(
        (el) => el.id
      )
    }
   this.allFunctionalCapacities =  await this.$store.dispatch("admin/getFunctionalCapacities")
  },
  methods: {
    changeUrl(data) {
      this.originalActivity.video.url = data.url
    },
    getDurationVideo(duration) {
      this.durationVideo = duration
    },
    formatOptions(options) {
      return Object.entries(options).map((entry) => ({
        value: entry[0],
        name: entry[1]
      }))
    },
    showRemoveActivityPopup() {
      this.showRemoveModal = true
    },
    removeActivity() {
      this.$emit("removeActivity", this.originalActivity.id)
      this.showRemoveModal = false
    },
    async submit() {
      let obj = this.originalActivity
      obj.duration = parseInt(this.durationVideo)
      obj.equipments = obj.equipments.map((eq) => {
        return { id: eq }
      })
      obj.functionalCapacities = obj.functionalCapacities
        .map((fc) => Number(fc))
        .sort((a, b) => a - b)
      if (this.mode == "add") {
        obj.functionalCapacities = this.allFunctionalCapacities.filter(el =>  obj.functionalCapacities.includes(el.index) ).map(el =>  {return { id : el.id}})
        await this.$store.dispatch("admin/addAPA", obj)
      }
      if (this.mode == "edit") {
        obj.functionalCapacities = this.allFunctionalCapacities.filter(el =>  obj.functionalCapacities.includes(el.index) ).map(el =>  {return { id : el.id}})
        await this.$store.dispatch("admin/editAPA", obj)
      }
      this.$router.push({ name: "ActivitiesList" })
    }
  },
  computed: {
    ...mapGetters({
      equipmentsList: "admin/equipments"
    }),
    exerciseTypes() {
      return this.formatOptions(this.$t("exerciseType"))
    },
    difficultyLevels() {
      return this.formatOptions(this.$t("exerciseIntensity"))
    },
    functionalCapacities() {
      return this.formatOptions(this.$t("functionalCapacities"))
    },
    equipments() {
      return this.equipmentsList.map(({ id, name }) => ({ value: id, name }))
    },
    isUploading() {
      return this.isUploadingThumbnail || this.isUploadingVideo || this.isSubmitting
    },
    hasActivityChanged() {
      return !_.isEqual(this.originalActivity, this.activity)
    },
    isReadyToSave() {
      return (
        !this.originalActivity?.functionalCapacities ||
        !this.originalActivity?.image ||
        !this.originalActivity?.title ||
        !this.originalActivity?.video ||
        !this.originalActivity?.type ||
        !this.originalActivity?.intensity
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  padding: 10px 0;
  margin-top: 20px;

  .row {
    & > div {
      flex: 1;
    }
  }
}

.buttons-container {
  justify-content: center;
  margin: 20px 0;

  div {
    flex: 0;
  }
}

.message {
  flex-grow: 1;
}
</style>
