<template>
  <AdminView>
    <router-link class="back-link" :to="{ name: 'ActivitiesList' }"
      >&lt; Retour</router-link
    >

    <div v-if="mode === 'edit'">
      <h1 class="title-1">Modifier l'activité</h1>
      <ActivityForm
        :activity="activityComputed ? activityComputed : activity"
        @removeActivity="removeActivity"
        mode="edit"
      />
    </div>

    <div v-if="mode === 'add'">
      <h1 class="title-1">Ajouter une activité</h1>
      <ActivityForm
        mode="add"
        :activity="activityComputed ? activityComputed : activity"
      />
    </div>
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import ActivityForm from "@/components/Forms/ActivityForm"
export default {
  name: "Activity",
  components: {
    AdminView,
    ActivityForm
  },
  props: {
    mode: {
      type: String,
      default: "edit"
    }
  },
  data() {
    return {
      activity: {
        id: "1",
        title: "",
        image: {
          url: "",
          id: ""
        },
        imageId: "",
        video: {
          url: "",
          id: ""
        },
        videoId: "",
        description: "",
        functionalCapacities: "",
        equipments: [],
        intensity: 0,
        type: 0
      }
    }
  },
  async created() {
    const list = await this.$store.dispatch("admin/getActivities")
    if (this.$route.params.id) {
      this.activity = list.filter((el) => el.id == this.$route.params.id)[0]
    }
  },
  computed: {
    ...mapGetters({
      getActivity: "admin/getActivity"
    }),
    activityComputed() {
      const exerciseId = this.$route.params.id
      return this.getActivity(exerciseId)
    }
  },
  methods: {
    async removeActivity(id) {
      await this.$store.dispatch("admin/removeActivity", id)
      this.$router.push({ name: "ActivitiesList" })
    }
  }
}
</script>

<style lang="scss" scoped></style>
